@tailwind base;

@layer base {
  html {
    @apply text-gray-700;
  }
}

@tailwind components;
@tailwind utilities;

@import "./fonts.css";

* {
  box-sizing: border-box;
}
