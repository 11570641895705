
@font-face {
  font-family: "GTWalsheimPro-Black";
  font-weight: black;
  font-style: black;
  src: url("../assets/fonts/gt_walsheim_pro/GTWalsheimPro-Black.ttf") format('truetype'),
}

@font-face {
  font-family:  "GTWalsheimPro-ExtraBold";
  font-weight: extrabold;
  font-style: extrabold;
  src: url("../assets/fonts/gt_walsheim_pro/GTWalsheimPro-UltraBold.ttf") format('truetype'),
}


@font-face {
  font-family: "GTWalsheimPro-Bold";
  font-weight: bold;
  font-style: bold;
  src: url("../assets/fonts/gt_walsheim_pro/GTWalsheimPro-Bold.ttf") format('truetype'),
}

@font-face {
  font-family: "GTWalsheimPro-Medium";
  font-weight: medium;
  font-style: medium;
  src: url("../assets/fonts/gt_walsheim_pro/GTWalsheimPro-Medium.ttf") format('truetype'),
}

@font-face {
  font-family: "GTWalsheimPro";
  font-weight: normal;
  font-style: normal;
  src: url("../assets/fonts/gt_walsheim_pro/GTWalsheimPro-Regular.ttf") format('truetype'),
}